<div class="topline">
    <h1 mat-dialog-title>
        Select a User
    </h1>
</div>
<div mat-dialog-content>
    <form class="form" [formGroup]="form" padding>
        <mat-form-field class="rightspacer">
            <mat-label>Existing User Email</mat-label>
            <input matInput placeholder="" formControlName="email">
        </mat-form-field>
        <mat-action-list *ngIf="foundUsers.length > 0">
            <button *ngFor="let user of foundUsers"mat-list-item (click)="userSelected(user)">{{user.firstName}} {{user.lastName}} ({{user.email}})</button>
        </mat-action-list>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]='!selectedUserId' class="donebutton" color="primary" (click)="okClicked()">Done</button>
</div>
