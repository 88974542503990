import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SessionService} from './services/session/session.service';
import {ApiService} from './services/api/api.service';
import {TeamsService} from './services/teams/teams.service';
import {UiAlertService} from './services/ui-alert/ui-alert.service';
import {SpinnerService} from './services/spinner/spinner.service';
import {Plan, StripeService} from './services/stripe/stripe.service';
import {SnackBarComponent} from './components/snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { UsersService } from './services/users/users.service';
import {TransactionsService} from './services/transactions/transactions.service';
import {AppSettings} from './app.settings';
import {MetaService} from './services/meta/meta.service';
import {Dictionary} from 'highcharts';
import packageInfo from '../../package.json';
import {UtilsService} from './services/utils/utils.service';
import {Roles, TeamCompanyUsersService} from './services/teamcompanyusers/teamcompanyusers.service';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './components/modal/confirmdialog/confirmdialog.component';
import {ClientService} from './services/client/client.service';
import {SecretService} from "./services/secret/secret.service";
import {Amplify} from 'aws-amplify';
import {SecretsInterface} from "./interfaces/secrets/secrets.interface";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('sidenav') sidenav?: MatSidenav;
  title = 'Team Admin';
  public ready = false;
  public toolbarClass = '';
  public wellspaces: Array<any> = [];
  public filteredWellspaces: Array<any> = [];
  public currentWellspace: any = {};
  public isLoggedIn = false;
  public showLoading = false;
  public spinnerMessage = '';
  public wellspaceFilter = '';
  public badgeCount = 0;
  public approvalCount = 0;

  public isIntegrationAdmin = AppSettings.isIntegration();
  public isCustomerSupport = AppSettings.isCustomerSupport();
  public isBrokerAdmin = AppSettings.isBroker();
  public isSuperAdmin = false;

  public company: Dictionary<any> | undefined;
  public user: Dictionary<any> | undefined;
  public spinnerLevel = 0;
  public releaseVersion = '';
  public info = packageInfo.version;
  public roles!: Roles;

  public embedded = false;
  public currentUserInfo = '';
  private inactivityDialog: MatDialogRef<ConfirmDialogComponent, any> | undefined;
  private showAlertWhenCreateWellspace = false;
  protected readonly AppSettings = AppSettings;
  protected readonly window = window;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public router: Router,
    private sessionService: SessionService,
    private spinnerService: SpinnerService,
    private titleService: Title,
    private stripeService: StripeService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private teamsService: TeamsService,
    private uiAlertService: UiAlertService,
    private usersService: UsersService,
    private transactionsService: TransactionsService,
    private metaService: MetaService,
    private utilsService: UtilsService,
    private teamCompanyUsersService: TeamCompanyUsersService,
    private idle: Idle,
    public clientService: ClientService,
    private secretService: SecretService
  ) {
    this.spinnerService.visibility.subscribe(visible => {
      this.showLoading = visible;
      this.spinnerMessage = this.spinnerService.message;
    });
    if (!AppSettings.isLocal) {
      idle.setIdle(300);
      idle.setTimeout(30);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      idle.onTimeoutWarning.subscribe(countdown => {
        if (this.isLoggedIn) {
          const message = `You will be logged out due to inactivity in ${countdown} seconds. Do you wish to stay logged in?`;
          if (!this.inactivityDialog) {
            this.uiAlertService.presentAlertConfirm(`You will be logged out due to inactivity in ${countdown} seconds. Do you wish to stay logged in?`, (ref) => {
              this.inactivityDialog = ref
            }).then(confirm => {
              if (!confirm) {
                this.logoutClicked(true);
              }
            });
          } else {
            this.inactivityDialog.componentInstance.message = message;
            this.inactivityDialog.componentInstance.update();
          }
        }
      });
      idle.onTimeout.subscribe(() => {
        if (this.isLoggedIn) {
          this.logoutClicked(true);
        }
      });
      this.idle.watch();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.configureSideNav();
  }

  configureSideNav(): void {
    if (!this.sidenav) {
      return;
    }
    const smallScreen = window.innerWidth < 601 ? true : false;
    if (!smallScreen) {
      // @ts-ignore
      this.sidenav.mode = 'side';
      // @ts-ignore
      this.sidenav.opened = true;
    } else {
      // @ts-ignore
      this.sidenav.mode = 'over';
      // @ts-ignore
      this.sidenav.opened = false;
    }
  }
  ngOnInit(): void {
    this.configureAws();
    if (this.isCustomerSupport) {
      this.titleService.setTitle('Customer Support');
    }
    this.releaseVersion = AppSettings.getReleaseVersion();
    this.apiService.spinnerSubscribe((level => {
      this.spinnerLevel = level;
    }));
    this.teamsService.wellspaceChangedObservable.subscribe((wellspace: any) => {
      let localWellspace: string | null = localStorage.getItem('selected-wellspace') ?? null;
      if (localWellspace) {
        localWellspace = JSON.parse(localWellspace);
      }
      if (wellspace) {
        this.setWellspace(wellspace);
        void this.stripeService.checkIfHasSomePendingAch(wellspace.id);
      } else if (!wellspace && localWellspace) {
        this.gotoWellspace(localWellspace);
      } else {
        this.loadWellspaces();
      }
    });
    this.apiService.monitorLoginState(loggedIn => {
      this.isLoggedIn = loggedIn;
      if (!loggedIn) {
        this.ready = false;
        this.isLoggedIn = false;
        this.badgeCount = 0;
        this.approvalCount = 0;
        this.company = undefined;
        this.user = undefined;
      } else {
        if (AppSettings.isIntegration()) {
          this.metaService.init();
          setTimeout(() => {
            this.sessionService.getCompany().then(company => {
              setTimeout(() => {});
              this.company = company;
            });
            this.sessionService.getUser().then(user => {
              this.user = user;
            });
          }, 100);  // wait for JWT session token to be set
        }
        // this.ready = true;
      }
      this.loadWellspaces();
    });
    this.stripeService.pendingACHCount.subscribe(count => {
      this.badgeCount = count;
    });
    const apiParam = new URLSearchParams(document.location.search).get('api') || '';
    this.apiService.setBaseUrl(AppSettings.getEndpoint(), apiParam);
    [
      { name: 'plans', path: 'assets/icons/rocket-launch-outline.svg'},
      { name: 'bell', path: 'assets/icons/bell.svg'},
      { name: 'wallit', path: 'assets/logo/WallitLogo.svg'},
      { name: 'icon-eye-lock', path: 'assets/icons/icon-eye-lock.svg'},
      { name: 'icon-eye', path: 'assets/icons/icon-eye.svg'},
    ].forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.embedded = event.urlAfterRedirects.includes('insuranceprofile/embedded') || event.urlAfterRedirects.includes('brokeragency/member/enroll');
        this.toolbarClass = event.urlAfterRedirects === '/login' ? 'hidetoolbar' : '';
        if (event.urlAfterRedirects === '/' && this.sessionService.createTeam) {
          this.sessionService.createTeam = false;
          this.addWellspace();
        }
      }
    });
    this.usersService.meSubscribe((response: any) => {
      if (response) {
        this.currentUserInfo = this.utilsService.getUserName(response.user);
        this.isSuperAdmin = this.usersService.isAdmin();
        this.teamCompanyUsersService.getManagers().then(result => {
          this.ready = true;
          this.roles = this.teamCompanyUsersService.roles;
          if (this.isCustomerSupport || this.isBrokerAdmin) {
            if (!this.roles.supportAgent && !this.roles.manager && !this.roles.supportAdmin && !this.isSuperAdmin && !this.roles.broker && !this.roles.agency && !this.roles.member && !this.roles.client) {
              this.uiAlertService.presentMessage('You are neither an admin, broker, agent, member, client, customer support manager, or customer support agent. You will be logged out.').then(() => {
                this.logoutClicked(false);
              });
            }
          }
        }).catch(err => {
          console.error(err);
        });
      }
    });
  }

  private configureAws(): void {
    this.secretService.secrets.subscribe({
      next: (secrets: SecretsInterface | null): void => {
        if (secrets) {
          Amplify.configure({
            Auth: {
              region: secrets.region,
              userPoolId: secrets.userPoolId,
              userPoolWebClientId: secrets.userPoolWebClientId,
            },
            oauth: {
              name: 'wallit',
              domain: secrets.domain,
              scope: [
                'email',
                'openid',
                'profile'
              ],
              responseType: 'code'
            }
          });
        }
      }
    });
  }

  setWellspace(wellspace: any): void {
    this.currentWellspace = wellspace;
    wellspace.role = wellspace.ownerUserId === this.sessionService.getUserId() ? '' : 'admin';
    if (wellspace && this.usersService.getCurrentUserId()) {
      this.transactionsService.getPurchases(wellspace.id, this.usersService.getCurrentUserId(), '').then(results => {
        this.approvalCount = results.length;
      });
    }
  }

  leftTitle(): string {
    if (!this.isBrokerAdmin && !this.isCustomerSupport) {
      return `Wallit${this.releaseVersion ? ' ' + this.releaseVersion : ''} ${this.isIntegrationAdmin ? 'Integration ' : ''}Admin`;
    }
    if (this.isBrokerAdmin && !this.isCustomerSupport) {
      return 'Wallit Broker & Agency Admin';
    }
    if (this.isCustomerSupport) {
      return `Customer Support ${this.isSuperAdmin ? 'Super Admin' : this.roles.supportAdmin ? 'Admin' : 'Agent'}`
    }
    return '';
  }

  private loadWellspaces(name?: string): void {
    if (this.isLoggedIn && !this.isIntegrationAdmin && !this.isBrokerAdmin) {
      setTimeout(() => {
        this.teamsService.getUserTeamsInfo(this.sessionService.getUserId()).then(teams => {
          this.wellspaces = this.filteredWellspaces = teams;
          this.wellspaces.forEach(wellspace => {
            wellspace.role = wellspace.ownerUserId === this.sessionService.getUserId() ? '' : 'admin';
          });
          this.ready = true;
          if (teams.length === 1) {
            this.gotoWellspace(teams[0]);
          } else if (name) {
            this.gotoWellspace(teams.find((team: any) => team.name === name));
          }
        }).catch(() => {
          this.wellspaces = this.filteredWellspaces = [];
        });
      }, 100);
    } else {
      this.wellspaces = this.filteredWellspaces = [];
    }
  }

  applyFilter(event: any): void {
    event.stopPropagation();
    let value = event.target.value;
    value = value.trim();
    value = value.toLowerCase();
    this.wellspaceFilter = value;
    this.setFilter();
    setTimeout(() => {
      event.target.focus();
    }, 200);
  }

  clearFilter(event: any): void {
    event.stopPropagation();
    this.wellspaceFilter = '';
    this.setFilter();
  }

  private setFilter(): void {
    this.filteredWellspaces = this.wellspaces.filter(wellspace => wellspace.name.toLowerCase().includes(this.wellspaceFilter));
  }

  toggleNav(): void {
    this.sidenav?.toggle();
  }

  wallitClicked(): void {
    this.gotoApp();
  }

  gotoApp(): void {
    this.usersService.loginUserV3(this.usersService.getCurrentUserId(), false, null);
  }

  navigate(route: string): void {
    // this.isBrokerAdmin = route === 'externalbrokeradmin';
    this.router.navigate([route]);
  }

  logoutClicked(showTimeoutPopup: boolean = false): void {
    this.sidenav?.close();
    this.sessionService.logout();
    this.router.navigate(['login', showTimeoutPopup]).then(() => window.location.reload());
  }

  notificationsClicked(): void {
    this.snackBar.openFromComponent(SnackBarComponent, {data: 'Coming soon!'});
  }

  launchClicked(): void {
    this.gotoApp();
  }

  helpClicked(): void {
    window.open('https://help.wallit.app');
  }

  plansClicked(): void {
    window.open('https://help.wallit.app/pages/Wallit-plans-and-features.html');
  }

  actionsClicked(): void {
    this.router.navigate(['admin/actions']);
  }

  supportUsersClicked(): void {

  }

  addWellspace(): void {
    const checkAllTeamUserToSeeIfAdmin: boolean = this.wellspaces.some(team => team && team.role && team.role === 'admin');
    if ( checkAllTeamUserToSeeIfAdmin ) {
      const msg = 'Are you sure you want to proceed? You will become the team owner, ' +
          'responsible for setting up payment methods. You might prefer to ask your team admin to create a new team with you as the delegated admin.';
      this.uiAlertService.presentAlertConfirm(msg).then((confirm: boolean): void => {
        if (confirm) {
          this.createNewWellspace();
        }
      });
    } else {
      this.createNewWellspace();
    }
  }

  createNewWellspace(): void {
    this.utilsService.addWellspace().then((result: string | Dictionary<any>): void => {
      if (result.length === 0) { return; }
      if (typeof result === 'string') {
        this.loadWellspaces(result);
      } else {
        this.setWellspace(result);
        this.teamsService.setWellspace(result);
      }
    });
  }

  gotoWellspace(wellspace: any): void {
    this.setWellspace(wellspace);
    this.teamsService.setWellspace(wellspace);
  }

  checkClientFeaturePermission(): boolean {
    return this.clientService.checkUserFeaturePermission();
  }


}
