import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../app.settings';
import { BehaviorSubject } from 'rxjs';
import { SecretsInterface } from '../../interfaces/secrets/secrets.interface';

@Injectable({
    providedIn: 'root'
})
export class SecretService {

    private _secrets: BehaviorSubject<SecretsInterface | null> = new BehaviorSubject<SecretsInterface | null>(null);

    constructor(private http: HttpClient) {
        if (!this._secrets.getValue()) {
            this.getAppSecrets();
        }
    }

    get secrets(): BehaviorSubject<SecretsInterface | null> {
        return this._secrets;
    }

    get secretsPromise(): Promise<SecretsInterface | null> {
        if (this._secrets.value) {
            return Promise.resolve(this._secrets.value);
        } else {
            return this._secrets.toPromise();
        }
    }

    getAppSecrets(): void {
        let env: string = AppSettings.getCurrentEndpointEnv();
        if (env === 'local') {
            env = 'qa';
        }
        // TODO: this ternary is a temporary fix, we should check why cannot get endpoint when is production with a brand domain.
        const secretEndpoint = `https://be-playground.api.${env === '' ? 'prod' : env}.wallit.app/secrets`;
        this.http.get<SecretsInterface>(secretEndpoint).subscribe({
            next: (secrets: SecretsInterface): void => {
                this._secrets.next(secrets);
            },
            error: err => {
                console.error('Error retrieving secrets', err);
            }
        });
    }
}
