<div class="topline">
    <h1 class="header">Manage Customers</h1>
    <div class="rightfabs">
        <button mat-fab (click)="playHelpMovie()"><mat-icon>movie</mat-icon></button>
        <button mat-fab (click)="addUser()"><mat-icon>add</mat-icon></button>
    </div>
</div>
<form class="filters" [formGroup]="form">
    <input class="search" #search matInput placeholder="Search users by name, email, phone number, SSN...">
    <mat-select class="partner" formControlName="partner" name="partner">
        <mat-option selected [value]="0">All Partners</mat-option>
        <mat-option *ngFor="let partner of partners; let i = index" [value]="i+1">{{partner.name}}</mat-option>
    </mat-select>
</form>
<div class="wholescreen">
    <span *ngIf="items.data.length === 0 && !loading">No customers match your search criteria.</span>
    <div *ngIf="items.data.length > 0" class="scroll-container" (scroll)="onScroll($event)">
        <table class="table" mat-table matSort [dataSource]="items">
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Created</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.createdAt | date: 'mediumDate'}}</td>
            </ng-container>
            <ng-container matColumnDef="partnerImage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Partner</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">
                    <div class="partnercell">
                        <img class="partnerimage" [src]="item.partnerImage">
                        <span>{{item.partnerName}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="signedUpBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Signed Up By</th>
                <td class="cell clickable" mat-cell *matCellDef="let item" (click)="signedUpByClicked(item)">{{item.signedUpBy}}</td>
            </ng-container>
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">First Name</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.firstName}}</td>
            </ng-container>
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Last Name</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.lastName}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Email</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.email}}<mat-icon *ngIf="item.isVerifiedEmail" class="checkicon">check</mat-icon></td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Phone Number</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.phoneNumber}}<mat-icon *ngIf="item.isVerified" class="checkicon">check</mat-icon></td>
            </ng-container>
            <ng-container matColumnDef="address1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Address 1</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.address1}}</td>
            </ng-container>
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">City</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.city}}</td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">State</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.state}}</td>
            </ng-container>
            <ng-container matColumnDef="postalCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">ZIP Code</th>
                <td class="cell" mat-cell *matCellDef="let item" (click)="userEdit(item)">{{item.postalCode}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td class="cell" mat-cell *matCellDef="let item">
                    <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="transactionMenu" [matMenuTriggerData]="{item: item}"><mat-icon class="more" matTooltip="Actions">more_vert</mat-icon></button>
                </td>
            </ng-container>
            <mat-menu #transactionMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                    <button mat-menu-item (click)="userLogin(item)">Login as User</button>
                    <button mat-menu-item (click)="userEdit(item)">Modify User Profile</button>
                    <button mat-menu-item (click)="loginHistory(item)">View Login History</button>
                    <mat-divider></mat-divider>
                    <button *ngIf="item.isActivatedUser" mat-menu-item (click)="changePartner(item)">Change Partner</button>
                    <button *ngIf="item.isActivatedUser" mat-menu-item (click)="sendPasswordReset(item)">Send Password Reset Email</button>
                    <button *ngIf="isSuperAdmin" mat-menu-item (click)="setPassword(item)">Set Password</button>
                    <button *ngIf="item.isActivatedUser" mat-menu-item (click)="disableUser(item)">Disable User</button>
                    <button *ngIf="!item.isActivatedUser" mat-menu-item (click)="enableUser(item)">Enable User</button>
                </ng-template>
            </mat-menu>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr [matTooltip]="rows.isActivatedUser ? '' : 'User is disabled'" [class]="rows.isActivatedUser ? 'listitem' : 'listitem strikethrough'" mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
        </table>
        <div *ngIf="loading" class="loading-indicator">
            Loading more items...
        </div>
    </div>
    <div *ngIf="iFrameSource" class="iframewrapper">
        <h2>Logged in as {{iFrameTitle}}</h2>
        <button mat-raised-button color="primary" (click)="closeIframe()"><mat-icon>drag_indicator</mat-icon>Close</button>
        <!--
        <iframe height="800" width="500" [src]="iFrameSource" id="iframe"></iframe>
        -->
    </div>
</div>
