
<div class="payment-report-container">

    <div class="payment-report-title">
        <h1>Payment Report</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'payment-report'})">
            <mat-icon>file_download</mat-icon>Export to CSV
        </button>
    </div>

    <div class="payment-report-table-container">
        
        <table mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="paymentsList" class="mat-elevation-z4">

            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">User Name</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.userName || '---'}}</td>
            </ng-container>

            <ng-container matColumnDef="userId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">User ID</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.userId || '---'}}</td>
            </ng-container>

            <ng-container matColumnDef="yearMonth">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Month</th>
                <td class="cell" mat-cell *matCellDef="let item">{{(item.yearMonth | date: 'MMMM') || '---'}}</td>
            </ng-container>

            <ng-container matColumnDef="allowanceSpent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Monthly Allowance+Rewards Spent ($)</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.allowanceSpent || '---'}}</td>
            </ng-container>

            <ng-container matColumnDef="transactions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Monthly Reimbursements Transactions (#)</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.transactions || 0}}</td>
            </ng-container>

            <ng-container matColumnDef="allowanceBalance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Allowance Balance ($)</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.allowanceBalance || '---'}}</td>
            </ng-container>

            <ng-container matColumnDef="wallitBalance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Wallit Balance ($)</th>
                <td class="cell" mat-cell *matCellDef="let item">{{item.wallitBalance || '---'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let rows; columns: columnsToDisplay"></tr>
        </table>

        <p class="no-payment-text" *ngIf="paymentsList.data.length <= 0">
            No Payment Report found!
        </p>

    </div>

</div>
