import { Component, OnInit, ViewChild } from '@angular/core';
import { TransactionsService } from '../../../../services/transactions/transactions.service';
import { TeamsService } from '../../../../services/teams/teams.service';
import { MatSort } from '@angular/material/sort';
import { AllowanceAllocationReportInterface } from './allowance-allocation-report.interface';
import { MatTableDataSource } from '@angular/material/table';


@Component({
    selector: 'app-analytics-allowance-allocation-report',
    templateUrl: './analytics-allowance-allocation-report.component.html',
    styleUrls: ['./analytics-allowance-allocation-report.component.scss']
})
export class AnalyticsAllowanceAllocationReportComponent implements OnInit {
    @ViewChild(MatSort) sort!: MatSort;
    private teamId = '';
    public allowanceAllocationList: MatTableDataSource<AllowanceAllocationReportInterface> = new MatTableDataSource<AllowanceAllocationReportInterface>([]);
    public columnsToDisplay: string[] = ['month', 'monthlyAllowanceSent', 'monthlyAllowanceSpent'];

    constructor(
        private transactionService: TransactionsService,
        private teamService: TeamsService
    ) { }

    ngOnInit(): void {
        this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
            if (team && team.id) {
                this.teamId = team.id;
                this.getAllowanceAllocationList();
            }
        });
    }

    getAllowanceAllocationList(): void {
        if (this.teamId) {
            this.allowanceAllocationList.data = [];
            this.transactionService.getAllowanceAllocationReport(this.teamId).then((allowanceAllocationReportData: AllowanceAllocationReportInterface[]): void => {
                this.allowanceAllocationList.data = allowanceAllocationReportData;
                this.allowanceAllocationList.sort = this.sort;
            }).catch(err => {
                this.allowanceAllocationList.data = [];
                console.error('Error in get allowance allocation report', err);
            });
        }
    }
}
