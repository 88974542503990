import {Component, HostBinding, Input, OnDestroy, Optional, Self} from '@angular/core';
import {JsonEditorOptions} from 'ang-jsoneditor';
import {Subject} from 'rxjs';
import {NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';

@Component({
  selector: 'app-json',
  templateUrl: './json.component.html',
  styleUrls: ['./json.component.scss'],
  providers: [
        {
            provide: MatFormFieldControl,
            useExisting: JsonComponent
        }
    ]
})
export class JsonComponent implements OnDestroy {

  static nextId = 0;

  @HostBinding() id = `json-input-${JsonComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

 @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;

  value: object | null = null;
  initialValue: object | null = null;

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'json-input';

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  // Called when the value changes
  writeValue(value: any): void {
    this.value = value;
    this.initialValue = value;
  }

  // Registers a callback function for when the control's value changes in the UI
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Registers a callback function for when the control is touched
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Called when the component is disabled or enabled
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Update value and notify parent form
  handleChange(value: object): void {
      if (!(value instanceof Event)) {
          this.value = value;
          this.onChange(value); // Notify Angular form control
          this.onTouched(); // Mark as touched
      }
  }

  // MatFormFieldControl

  get empty(): boolean {
    return !this.value;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName.toLowerCase() !== 'input') {
      (event.target as HTMLElement).focus();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  // Update focus state
  onFocus(): void {
    this.focused = true;
    this.stateChanges.next();
  }

  onBlur(): void {
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  editorOptions(): any {
    const options = new JsonEditorOptions();
    options.modes = ['text', 'tree', 'view'];
    return options;
  }

}
