<div class="allowance-allocation-report-container">

    <div class="allowance-allocation-report-title">
        <h1>Monthly Allowance Allocation Report</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'allowance-allocation-report'})">
            <mat-icon>file_download</mat-icon>Export to CSV
        </button>
    </div>

    <div class="allowance-allocation-report-table-container">

        <table mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="allowanceAllocationList" class="mat-elevation-z4">

            <ng-container matColumnDef="month">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Month</th>
                <td mat-cell *matCellDef="let element"> {{element.month ?? '---'}} </td>
            </ng-container>

            <ng-container matColumnDef="monthlyAllowanceSent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Monthly Allowances Sent ($)</th>
                <td mat-cell *matCellDef="let element"> {{(element.monthlyAllowanceSent | currency) ?? 0 }} </td>
            </ng-container>

            <ng-container matColumnDef="monthlyAllowanceSpent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Monthly Allowances Spent ($)</th>
                <td mat-cell *matCellDef="let element"> {{(element.monthlyAllowanceSpent | currency) ?? 0}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

        </table>

        <p class="no-payment-text" *ngIf="allowanceAllocationList.data.length <= 0">
            No Monthly Allowance Allocation Report found!
        </p>

    </div>

</div>
