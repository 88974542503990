import { Injectable } from "@angular/core";
import { SecretsInterface } from "src/app/interfaces/secrets/secrets.interface";
import { SecretService } from "../secret/secret.service";

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {

    constructor(private secretsService: SecretService) { }


    async isEnabled(flag: keyof SecretsInterface): Promise<boolean> {
        try {
            const secrets = await this.secretsService.secretsPromise;
            if (secrets) {
                const teamIds = JSON.parse(secrets[flag]);
                if (teamIds && Array.isArray(teamIds) && teamIds.length > 0) {
                    const currentTeam = this.getCurrentTeam();
                    if (currentTeam && teamIds.find(teamId => teamId === currentTeam.id)) return true
                }
            }
            return false;
        } catch {
            return false;
        }
    }

    private getCurrentTeam(): { id: string } | null {
        const data = localStorage.getItem('selected-wellspace');
        try {
            return data ? JSON.parse(data) : null;
        } catch {
            return null;
        }
    }

}
