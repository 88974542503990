import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TeamsService } from 'src/app/services/teams/teams.service';
import { TransactionsService } from 'src/app/services/transactions/transactions.service';
import { RewardsReportInterface } from './rewards-report.interface';

@Component({
  selector: 'app-analytics-rewards-report',
  templateUrl: './analytics-rewards-report.component.html',
  styleUrls: ['./analytics-rewards-report.component.scss']
})
export class AnalyticsRewardsReportComponent {
    @ViewChild(MatSort) sort!: MatSort;
    private teamId = '';
    public rewardsList: MatTableDataSource<RewardsReportInterface> = new MatTableDataSource<RewardsReportInterface>([]);
    public columnsToDisplay: string[] = ['rewardsSent'];

    constructor(
        private transactionService: TransactionsService,
        private teamService: TeamsService
    ) { }

    ngOnInit(): void {
        this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
            if (team && team.id) {
                this.teamId = team.id;
                this.getRewardsList();
            }
        });
    }

    getRewardsList(): void {
        if (this.teamId) {
            this.rewardsList.data = [];
            this.transactionService.getRewardsReport(this.teamId).then((rewardsReportData: RewardsReportInterface[]): void => {
                this.rewardsList.data = rewardsReportData;
                this.rewardsList.sort = this.sort;
            }).catch(err => {
                this.rewardsList.data = [];
                console.error('Error in get rewards report', err);
            });
        }
    }
}
