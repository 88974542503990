import { Component, OnInit, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { UsersService } from 'src/app/services/users/users.service';
import { SnackBarComponent } from '../snackbar/snackbar.component';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-email-code',
  templateUrl: './email-code.component.html',
  styleUrls: ['./email-code.component.scss'],
})
export class EmailCodeComponent implements OnInit {
  email?: string;
  changedEmail?: string;

  @ViewChildren('input') inputs: any;

  constructor(
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private userService: UsersService,
    private snackBar: MatSnackBar,
    private sessionService: SessionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.email = params.email;
    });
    this.route.queryParams.subscribe((params) => {
      this.changedEmail = params.changedEmail;
    });
    this.sendEmail(true);
  }

  sendEmail(onInit: boolean = false): void {
    this.userService.resendEmailValidation(undefined, this.changedEmail).then(() => {
      if (!onInit) { this.snackBar.openFromComponent(SnackBarComponent, {data: 'Validation code has been sent to your email'}); }
    });
  }


  onCodeCompleted(v: string): void {
    this.userService.validateEmailCode(this.userService.getCurrentUserId(), v).then(() => {
      if (this.changedEmail) {
        this.userService.updateUser(this.userService.getCurrentUserId(), { email: this.changedEmail }).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: 'Your email address has been changed. Logging out...', });
          setTimeout(() => {
            this.logOut();
          }, 3000);
        });
      } else {
      }
    })
    .catch((e) => {
      if (e.data.errors === 'Invalid verification code') {
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: 'Invalid verification code',
        });
      }
    });
  }

  logOut(): void {
    this.sessionService.logout();
    this.router.navigate(['login']).then(() => window.location.reload());
  }

}
