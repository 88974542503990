import {Component, OnInit, ViewChild} from '@angular/core';
import {TransactionsService} from '../../../../services/transactions/transactions.service';
import {TeamsService} from '../../../../services/teams/teams.service';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {PaymentReportInterface} from './payment-report.interface';

@Component({
  selector: 'app-analytics-payment-report',
  templateUrl: './analytics-payment-report.component.html',
  styleUrls: ['./analytics-payment-report.component.scss']
})
export class AnalyticsPaymentReportComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  private teamId = '';
  public paymentsList: MatTableDataSource<PaymentReportInterface> = new MatTableDataSource<PaymentReportInterface>([]);
  public columnsToDisplay: string[] = ['userName', 'userId', 'yearMonth', 'allowanceSpent', 'transactions', 'allowanceBalance', 'wallitBalance'];

  constructor(
      private transactionService: TransactionsService,
      private teamService: TeamsService
  ) {}

  ngOnInit(): void {
    this.teamService.wellspaceChangedObservable.subscribe((team: any): void => {
      if (team && team.id) {
        this.teamId = team.id;
        this.getPaymentReportList();
      }
    });
  }

  getPaymentReportList(): any {
    if (this.teamId) {
      this.paymentsList.data = [];
      this.transactionService.getTeamPaymentReport(this.teamId).then((paymentReportList: PaymentReportInterface[]): void => {
        this.paymentsList.data = paymentReportList;
        this.paymentsList.sort = this.sort;
      }).catch(err => {
        this.paymentsList.data = [];
        console.error('Error in get payment report', err);
      });
    }
  }

}
