import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FieldType, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {VendorGroupsProductsService} from '../../../services/vendor-groups-products/vendor-groups-products.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {VendorGroupsPlansService} from '../../../services/vendor-groups-plans/vendor-groups-plans.service';
import {VendorGroupsRatesService} from '../../../services/vendor-groups-rates/vendor-groups-rates.service';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {TeamCompaniesService} from '../../../services/teamcompanies/teamcompanies.service';
import {UtilsService} from '../../../services/utils/utils.service';

@Component({
  selector: 'app-vendor-groups-rates',
  templateUrl: './vendor-groups-rates.component.html',
  styleUrls: ['./vendor-groups-rates.component.scss']
})
export class VendorGroupsRatesComponent implements OnInit {

  protected allColumnNames = ['vendorProductId', 'companyId', 'metadata', 'actionmenu'];
  protected items = new MatTableDataSource<any>();
  private companies: any;
  private products: any;

  private addEditFields = [
    {name: 'vendorProductId', label: 'Vendor Product', fieldType: FieldType.F_DROPDOWN, values: [], required: true},
    {name: 'companyId', label: 'Company', fieldType: FieldType.F_DROPDOWN, values: [], required: true},
    {name: 'metadata', label: 'Configuration JSON', fieldType: FieldType.F_JSON, required: true},
  ];

  constructor(
      private vendorGroupsProductsService: VendorGroupsProductsService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private vendorGroupsRatesService: VendorGroupsRatesService,
      private teamCompaniesService: TeamCompaniesService,
      private utilsService: UtilsService
  ) {

  }

  ngOnInit(): void {
    this.reloadItems();
  }

  private reloadItems(): void {
    this.vendorGroupsRatesService.getAllVendorGroupRates().then(rates => {
      this.items.data = rates;
    });
    this.vendorGroupsProductsService.getAllVendorGroupProducts().then(products => {
      this.products = products;
      this.addEditFields[0].values = products.map((vendor: any) => vendor.vendorProductName);
    });
    this.teamCompaniesService.getCompanies().then(companies => {
      this.companies = companies;
      this.addEditFields[1].values = companies.map((company: any) => `${company.name}:${company.id}`);
    });
  }

  getCompanyName(item: any): string {
    const thisCompany = this.companies.find((company: any) => company.id === item.companyId);
    return thisCompany ? thisCompany.name : '';
  }

  getVendorProductName(item: any): string {
   const product = this.products.find((vendorProduct: { id: any; }) => vendorProduct.id === item.vendorProductId);
   return product ?  `${product.vendorProductName} ${product.metadata.name} (${product.metadata.shortName})` : '';
  }

  addItem(): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(null, this.addEditFields, 'Group Rate')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsRatesService.createVendorGroupRate(dialogResult.vendorProductId, dialogResult.companyId, dialogResult.metadata).then(() => {
          this.reloadItems();
          this.snackBar.open('New group rate created');
        });
      }
    });
  }

  editItem(item: any): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(item, this.addEditFields, 'Group Rate')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsRatesService.modifyVendorGroupRate(item.id, dialogResult).then(() => {
          this.reloadItems();
          this.snackBar.open('Group rate updated');
        });
      }
    });
  }

  duplicateItem(item: any): void {
    this.vendorGroupsRatesService.createVendorGroupRate(item.vendorProductId, item.companyId, item.metadata).then(() => {
      this.reloadItems();
      this.snackBar.open('Duplicate group rate created');
    });
  }

  removeItem(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this group rate?').then(ok => {
      this.vendorGroupsRatesService.deleteVendorGroupRate(item.id).then(() => {
        this.reloadItems();
        this.snackBar.open('Group rate removed');
      });
    });
  }

  truncateJSONString(str: string, maxLength: number): string {
    str = JSON.stringify(str);
    return str.length <= maxLength ? str : str.slice(0, maxLength - 3) + '...';
  }

}
