<h1 mat-dialog-title>
    Change Partner
</h1>
<div mat-dialog-content>
    <form class="form" *ngIf="form" [formGroup]="form" padding>
        <mat-form-field>
            <mat-label>Current Partner</mat-label>
            <mat-select formControlName ="partner">
                <mat-option *ngFor="let partner of partners" [value]="partner.partnerId">{{partner.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div class="buttons" mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" class="donebutton" color="primary" (click)="okClicked()">OK</button>
    <button mat-button (click)="onDismiss()">Cancel</button>
</div>
