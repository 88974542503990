
<div class="rewards-report-container">

    <div class="rewards-report-title">
        <h1>Rewards Report</h1>
        <button class="exportbutton" mat-raised-button color="accent" (click)="exporter.exportTable('csv', {fileName: 'rewards-report'})">
            <mat-icon>file_download</mat-icon>Export to CSV
        </button>
    </div>

    <div class="rewards-report-table-container">

        <table mat-table matSort matTableExporter #exporter="matTableExporter" [dataSource]="rewardsList" class="mat-elevation-z4">

            <ng-container matColumnDef="rewardsSent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Total Rewards Sent</th>
                <td mat-cell *matCellDef="let element"> {{(element.rewardsSent | currency) ?? '0'}} </td>
            </ng-container>

            <!--<ng-container matColumnDef="allowanceSpent">-->
            <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None">Allowance Balance Spent ($)</th>-->
            <!--    <td mat-cell *matCellDef="let element"> {{(element.allowanceSpent | currency) ?? 0 }} </td>-->
            <!--</ng-container>-->
            <!---->
            <!--<ng-container matColumnDef="reimbursements">-->
            <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Sort Ascending/Descending/None"># of Reimbursements</th>-->
            <!--    <td mat-cell *matCellDef="let element"> {{element.reimbursements ?? 0}} </td>-->
            <!--</ng-container>-->

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

        </table>

        <p class="no-payment-text" *ngIf="rewardsList.data.length <= 0">
            No Rewards Report found!
        </p>

    </div>

</div>
