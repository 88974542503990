import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {UsersInsuranceService} from '../../../services/usersinsurance/users.insurance.service';
import {SnackBarComponent} from '../../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {Field, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {MatDialog} from '@angular/material/dialog';
import {ExporttocsvService} from '../../../services/exporttocsv/exporttocsv.service';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import {from} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {MatSort, SortDirection} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {enrollAddedFields} from '../client-enrollment/client-enrollment.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {UtilsService} from '../../../services/utils/utils.service';

export const State = {
  add: ['Add', 'add', 'A', 'a'],
  update: ['Update', 'update', 'U', 'u'],
  remove: ['Remove', 'remove', 'R', 'r']
};

export interface Table {
  title: string;
  addEditFields: Array<Field>;
  columnsToDisplay: Array<
    {
      data: (row: { [x: string]: any; }) => any ,
      label: string,
    }
  >;
  menuItems: Array<
    {
      name: string,
      disabled?: (arg: any) => boolean;
      click: (arg: any) => Promise<boolean>;
    }
  >;
  templateUrl: string;
  where: {
    limit: number;
    offset: number;
    filter: any;
    sort: string;
  };
}

@Component({
  selector: 'app-member-manager',
  templateUrl: './member-manager.component.html',
  styleUrls: ['./member-manager.component.scss']
})
export class MemberManagerComponent implements OnInit, OnChanges {

  public selection = new SelectionModel<any>(true, []);
  public items = new MatTableDataSource<any>();
  public columnLabels: any;
  public allColumnLabels: any;
  public columnData: any;
  public uploadErrors: {count: number, errorMessage: string, errorDetails: Array<{lineNumber: number, type: string, info: string}>} | undefined;
  public stop = false;
  public websocket: any;
  public infoMessage = '';

  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;
  @Input() table!: Table;
  @Input() companyId = '';
  @Input() state = '';
  @Input() reload = false;

  constructor(
      private usersInsuranceService: UsersInsuranceService,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private dialog: MatDialog,
      private exportToCsvService: ExporttocsvService,
      private spinnerService: SpinnerService,
      private clipboard: Clipboard,
      private utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.columnData = this.table.columnsToDisplay.map(column => column.data);
    this.columnLabels = this.table.columnsToDisplay.map(column => column.label);
    this.allColumnLabels = ['select', ...this.columnLabels, 'actionmenu'];
    this.table.menuItems.push({name: 'Edit', click: this.itemEdit.bind(this)});
    if (this.state === 'census') {
      this.table.menuItems.push({name: 'Remove', click: this.itemRemove.bind(this)});
    }
    this.loadItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadItems();
  }

  private loadItems(): void {
    this.infoMessage = '';
    if (this.companyId) {
      this.usersInsuranceService.getInsuranceState(this.companyId, this.state, this.table.where.offset, this.table.where.limit, this.table.where.filter, this.table.where.sort).then(result => {
        this.items.data = result.filter((item: { state: string; }) => item.state === this.state || this.state === 'census' || (this.state === 'eligible' && item.state === 'enrolled')).map((item: { metadata: { upload: any; }; }) => {
          return Object.assign({}, item, item.metadata.upload);
        });
        this.items.sort = this.sort;
        this.items.sortingDataAccessor = (item: { [x: string]: any; }, property: string) => this.table.columnsToDisplay.find(column => column.label === property)?.data(item);
      });
    } else {
      this.items.data = [];
    }
  }

  private itemEdit(item: any): Promise<boolean> {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(item, this.table.addEditFields || [], this.table.title || '')
    });
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          item.metadata.upload = _.merge({}, item.metadata.upload, dialogResult);
          this.usersInsuranceService.modifyInsuranceState(item.id, {metadata: item.metadata}).then(() => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Item updated`});
            resolve(true);
          });
        } else {
          resolve(false);
        }
      });
    });
  }

  private itemRemove(item: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.uiAlertService.presentAlertConfirm(`Do you really want to remove this ${this.state} item?`).then(ok => {
        if (ok) {
          if (this.state === 'census' && item.state !== 'census') {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `You cannot remove this census item since it is already set to eligible`});
            resolve(false);
          } else if (this.state === 'eligible' && item.state !== 'eligible') {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `You cannot remove this eligible item since it is already set to enrolled`});
            resolve(false);
          } else {
            this.usersInsuranceService.deleteInsuranceState(item.id).then(() => {
              this.snackBar.openFromComponent(SnackBarComponent, {data: 'Item removed'});
              resolve(true);
            }).catch(error => {
              this.snackBar.openFromComponent(SnackBarComponent, {data: `Error removing ${this.state} item: ${error}`});
              resolve(false);
            });
          }
        } else {
          resolve(false);
        }
      });
    });
  }

  export(): void {
    this.exportToCsvService.export(`${this.state}-${moment()}`, _.map(this.items.data, item => Object.assign( {Action: 'Add'}, _.pick(item, this.table.addEditFields.map(field => field.name)))));
    this.snackBar.openFromComponent(SnackBarComponent, {data: 'Download completed' });
  }

  addItem(): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(null, this.table.addEditFields || [], this.table.title || '')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.usersInsuranceService.createInsuranceState(this.companyId, '', dialogResult.Email, dialogResult, this.state).then(() => {
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Item added`});
          this.loadItems();
        });
      }
    });
  }

  downloadTemplate(): void {
    this.snackBar.openFromComponent(SnackBarComponent, {data: 'Template download completed' });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.items.data.length;
    return numSelected === numRows;
  }

  isSomeSelected(): boolean {
    return this.selection.selected.length > 0;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(ref: { checked: boolean; }): void {
    if (this.isSomeSelected()) {
      this.selection.clear();
      ref.checked = false;
    } else {
      ref.checked = true;
      this.items.data.forEach((row: any) => this.selection.select(row));
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const webhookCallback = (isData: boolean, data: any, ws?: any) => {
        if (isData) {
          this.snackBar.openFromComponent(SnackBarComponent, {data});
          this.websocket = ws;
          console.log('WEBHOOKCALLBACK', !!ws);
        } else {
          this.websocket = data;
        }
      };
      // this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        event.target.value = '';
        this.infoMessage = 'Do not close this browser window while upload is in progress. Doing so will interrupt the processing.';
        this.usersInsuranceService.uploadInsuranceState(this.companyId, this.state, reader.result, webhookCallback).then(result => {
          this.loadItems();
          this.usersInsuranceService.getCounts(this.companyId);
          this.snackBar.openFromComponent(SnackBarComponent, {data: `Upload completed successfully. ${result.count} records processed.` });
          this.websocket = null;
          this.infoMessage = '';
        }).catch(error => {
          this.usersInsuranceService.getCounts(this.companyId);
          this.uploadErrors = error.error;
          this.websocket = null;
          this.infoMessage = '';
        });
      };
      reader.readAsArrayBuffer(file);
    }
  }

  removeSelected(): void {
    const notIncluded = this.selection.selected.filter(item => item.state !== 'census');
    if (this.selection.selected.length === notIncluded.length) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `None of the items you have selected can be removed since they are in either the eligible or enrolled state.`});
      return;
    }
    const actionCount = this.selection.selected.length - notIncluded.length;
    this.uiAlertService.presentAlertConfirm(`${notIncluded.length > 0 ? 'Some of the items you have selected cannot be removed because they are either in the eligible or enrolled state. ' : ''}Do you really want to remove ${actionCount} ${notIncluded.length > 0 ? 'remaining ' : ''} item${actionCount === 1 ? '' : 's'}?`).then(ok => {
      if (ok) {
        const calls = [];
        for (const [index, item] of this.selection.selected.entries()) {
          const func = () => {
            this.spinnerService.show(`Removing ${index + 1} of ${this.selection.selected.length - notIncluded.length}`);
            return this.usersInsuranceService.deleteInsuranceState(item.id);
          };
          if (item.state === 'census') {
            calls.push(func);
          }
        }
        from(calls).pipe(concatMap(call => call())).subscribe({
          complete: () => {
            this.spinnerService.hide();
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Items removed`});
            this.selection.clear();
            this.loadItems();
          }
        });
      }
    });
  }

  eligibleSelected(): void {
    const notIncluded = this.selection.selected.filter(item => item.state !== 'census');
    if (this.selection.selected.length === notIncluded.length) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `None of the items you have selected can be set to eligible since they are in either the eligible or enrolled state.`});
      return;
    }
    const actionCount = this.selection.selected.length - notIncluded.length;
    this.uiAlertService.presentAlertConfirm(`${notIncluded.length > 0 ? 'Some of the items you have selected cannot be made eligible because they are either in the eligible or enrolled state. ' : ''}Do you really want to set ${actionCount} ${notIncluded.length > 0 ? 'remaining ' : ''} item${actionCount === 1 ? '' : 's'} to eligible?`).then(ok => {
      if (ok) {
        const calls = [];
        for (const [index, item] of this.selection.selected.entries()) {
          const func = () => {
            this.spinnerService.show(`Making eligible ${index + 1} of ${this.selection.selected.length - notIncluded.length}`);
            return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'eligible'});
          };
          if (item.state === 'census') {
            calls.push(func);
          }
        }
        from(calls).pipe(concatMap(call => call())).subscribe({
          complete: () => {
            this.spinnerService.hide();
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Items made eligible`});
            this.selection.clear();
            this.loadItems();
          }
        });
      }
    });  }

  ineligibleSelected(): void {
    const notIncluded = this.selection.selected.filter(item => item.state !== 'eligible');
    if (this.selection.selected.length === notIncluded.length) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `None of the items you have selected can be made ineligible since they are in the enrolled state.`});
      return;
    }
    const actionCount = this.selection.selected.length - notIncluded.length;
    this.uiAlertService.presentAlertConfirm(`${notIncluded.length > 0 ? 'Some of the items you have selected cannot be made ineligible because they are either in the enrolled state. ' : ''}Do you really want to set ${actionCount} ${notIncluded.length > 0 ? 'remaining ' : ''} item${actionCount === 1 ? '' : 's'} to ineligible?`).then(ok => {
      if (ok) {
        const calls = [];
        for (const [index, item] of this.selection.selected.entries()) {
          const func = () => {
            this.spinnerService.show(`Making ineligible ${index + 1} of ${this.selection.selected.length - notIncluded.length}`);
            return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'census'});
          };
          if (item.state === 'eligible') {
            calls.push(func);
          }
        }
        from(calls).pipe(concatMap(call => call())).subscribe({
          complete: () => {
            this.spinnerService.hide();
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Items made ineligible`});
            this.selection.clear();
            this.loadItems();
          }
        });
      }
    });
  }

  enrollSelected(): void {
    const notIncluded = this.selection.selected.filter(item => item.state !== 'eligible');
    if (this.selection.selected.length === notIncluded.length) {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `None of the members you have selected can be enrolled since they are already in the enrolled state.`});
      return;
    }
    const actionCount = this.selection.selected.length - notIncluded.length;
    this.uiAlertService.presentAlertConfirm(`${notIncluded.length > 0 ? 'Some of the members you have selected cannot be enrolled because they are already in the enrolled state. ' : ''}Do you really want to enroll ${actionCount} ${notIncluded.length > 0 ? 'remaining ' : ''} item${actionCount === 1 ? '' : 's'}?`).then(ok => {
      if (ok) {
        const dialogRef = this.dialog.open(MemberAddeditComponent, {
          data: new MemberAddeditDialogModel(null, enrollAddedFields, 'Required Enrollment Fields')
        });
        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            const calls = [];
            for (const [index, item] of this.selection.selected.entries()) {
              const func = () => {
                this.spinnerService.show(`Enrolling ${index + 1} of ${this.selection.selected.length - notIncluded.length}`);
                return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'enrolled'});
              };
              if (item.state === 'eligible') {
                calls.push(func);
              }
            }
            from(calls).pipe(concatMap(call => call())).subscribe({
              complete: () => {
                this.spinnerService.hide();
                this.snackBar.openFromComponent(SnackBarComponent, {data: `Members enrolled`});
                this.selection.clear();
                this.loadItems();
              }
            });
          }
        });
      }
    });
  }

  unenrollSelected(): void {
    const actionCount = this.selection.selected.length;
    this.uiAlertService.presentAlertConfirm(`Do you really want to un-enroll ${actionCount} member${actionCount === 1 ? '' : 's'}?`).then(ok => {
      if (ok) {
        const calls = [];
        for (const [index, item] of this.selection.selected.entries()) {
          const func = () => {
            this.spinnerService.show(`Unenrolling ${index + 1} of ${this.selection.selected.length}`);
            return this.usersInsuranceService.modifyInsuranceState(item.id, {state: 'eligible'});
          };
          if (item.state === 'enrolled') {
            calls.push(func);
          }
        }
        from(calls).pipe(concatMap(call => call())).subscribe({
          complete: () => {
            this.spinnerService.hide();
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Members unenrolled`});
            this.selection.clear();
            this.loadItems();
          }
        });
      }
    });
  }

  inviteSelected(): void {
    const okToEmail = this.selection.selected.filter(item => item.userId);
    const actionCount = okToEmail.length;
    if (actionCount > 0) {
      this.uiAlertService.presentAlertConfirm(`Do you really want to invite ${actionCount} member${actionCount === 1 ? '' : 's'}?`).then(ok => {
        if (ok) {
          this.usersInsuranceService.invite(this.companyId, this.state, okToEmail.map(item => item.id)).then(result => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `${result.signupCount} signup email${result.signupCount === 1 ? '' : 's'} sent. ${result.signinCount} signin email${result.signinCount === 1 ? '' : 's'} sent to existing users.`});
            this.selection.clear();
            this.loadItems();
          }).catch(error => {
            this.snackBar.openFromComponent(SnackBarComponent, {data: `Error sending emails`});
          });
        }
      });
    } else {
      this.snackBar.openFromComponent(SnackBarComponent, {data: `No eligible members. Members must be synced with carrier before being invited`});
      this.selection.clear();
    }
  }

  menuClick(menuItem: any, item: any): void {
    menuItem.click(item).then((update: boolean) => {
      if (update) {
        this.loadItems();
      }
    });
  }

  onHeaderClick(event: MouseEvent, column: string): void {
    let currentSortDirection: SortDirection = '';
    event.stopPropagation();
    event.preventDefault();
    if (this.sort.active === column) {
      currentSortDirection = this.getNextSortDirection(this.sort.direction);
    } else {
      currentSortDirection = 'asc';
    }
    const dbColumn = this.table.addEditFields.find(field => field.label === column);
    this.table.where.sort = `${dbColumn ? dbColumn.name : column}:${currentSortDirection}`;
    this.sort.active = column;
    this.sort.direction = currentSortDirection;
    this.loadItems();
  }

  getNextSortDirection(currentDirection: string): 'asc' | 'desc' | '' {
    if (currentDirection === 'asc') {
      return 'desc';
    } else if (currentDirection === 'desc') {
      return '';
    } else {
      return 'asc';
    }
  }

  copyToClipboard(): void {
    let message = this.uploadErrors?.errorMessage + '\n\n';
    for (const error of this.uploadErrors?.errorDetails || []) {
      message += `${error.type} '${error.info}', Line ${error.lineNumber}\n`;
    }
    this.clipboard.copy(message);
    this.snackBar.openFromComponent(SnackBarComponent, {data: `Messages copied to clipboard`});
  }

  stopTransfer(): void {
    this.websocket?.send('stop');
    this.websocket = null;
  }

  syncWithCarriers(): void {
    this.infoMessage = '';
    this.usersInsuranceService.getCarrierSyncStatus(this.companyId).then(statusInfo => {
      if (statusInfo.totalCount === 0) {
        this.snackBar.openFromComponent(SnackBarComponent, {data: `There are no records for this company that need to be synced with the carriers.`});
      } else {
        this.uiAlertService.presentAlertConfirm(`This will sync ${statusInfo.totalCount} record${statusInfo.totalCount === 1 ? '' : 's'} with the following carriers:<br><br>${statusInfo.carriers.map((carrier: { name: any; }) => carrier.name).join('<br>')}<br><br>Proceed?`).then(ok => {
          if (ok) {
            this.usersInsuranceService.syncWithCarriers(this.companyId).then(result => {
              this.loadItems();
              const syncedCount = result.totalCount - result.errors.length;
              this.infoMessage = `${syncedCount} ${this.utilsService.plural('record', syncedCount)} synced with ${statusInfo.carriers.length} ${this.utilsService.plural('carrier', statusInfo.carriers.length)}.`;
              if (result.errors.length > 0) {
                this.infoMessage += `<br>${result.errors.length} ${this.utilsService.plural('record', result.errors.length)} not processed due to errors.`;
                result.errors.forEach(error => {
                  this.infoMessage += `<br>${error}`;
                });
              }
            });
          }
        });
      }
    });
  }
}
