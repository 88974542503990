import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../../../services/users/users.service';
import {FormBuilder, UntypedFormControl, Validators} from '@angular/forms';

interface User {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-getuserdialog',
  templateUrl: './getuserdialog.component.html',
  styleUrls: ['./getuserdialog.component.scss']
})
export class GetUserDialogComponent implements OnInit {

  protected selectedUserId = '';
  protected foundUsers = Array<User>();
  protected allUsers = Array<User>();
  protected form;

  constructor(
      public dialogRef: MatDialogRef<GetUserDialogComponent>,
      private usersService: UsersService,
      private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('')
    });
    this.usersService.queryUsers('').then(result => {
      this.allUsers = result;
      this.foundUsers = result;
    });
  }

  ngOnInit(): void {
    this.form.get('email')?.valueChanges.subscribe((value: string) => {
      console.log('CHANGED')
      if (value) {
        this.usersService.queryUsers(value).then(result => {
          this.foundUsers = result.slice(0, 10);
          this.selectedUserId = '';
        });
      } else {
        this.foundUsers = [];
      }
    });
  }

  okClicked(): void {
    this.dialogRef.close(this.selectedUserId);
  }

  userSelected(user: any): void {
    this.selectedUserId = user.id;
  }
}
