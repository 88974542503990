import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TeamsService} from '../../../services/teams/teams.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditApproverDialogModel} from '../editapproverdialog/editapproverdialog.component';
import {UsersService} from '../../../services/users/users.service';

@Component({
  selector: 'app-changepartnerdialog',
  templateUrl: './changepartnerdialog.component.html',
  styleUrls: ['./changepartnerdialog.component.scss']
})
export class ChangePartnerDialogComponent implements OnInit {


  public form: UntypedFormGroup;
  private partnerId: string;
  protected partners: any;

  constructor(
      private formBuilder: UntypedFormBuilder,
      private usersService: UsersService,
      public dialogRef: MatDialogRef<ChangePartnerDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ChangePartnerDialogModel
  ) {
    this.partnerId = data.partnerId;
    this.form = this.formBuilder.group({
      partner: new UntypedFormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.usersService.getVisibleBrands().then(partners => {
      this.partners = partners;
      console.log('PARTNERID', this.partnerId);
      this.form.get('partner')?.setValue(this.partnerId);
    });
  }

  private getFormField(name: string): any {
    const field = this.form.get(name);
    return field ? field.value : undefined;
  }

  okClicked(): void {
    this.dialogRef.close(this.form.get('partner')?.value);
  }

  onDismiss(): void {
    this.dialogRef.close(0);
  }

}

export class ChangePartnerDialogModel {
  constructor(public partnerId: string) {
  }
}

