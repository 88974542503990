import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {FieldType, MemberAddeditComponent, MemberAddeditDialogModel} from '../member-addedit/member-addedit.component';
import {VendorGroupsProductsService} from '../../../services/vendor-groups-products/vendor-groups-products.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UiAlertService} from '../../../services/ui-alert/ui-alert.service';
import {VendorProductsService} from '../../../services/vendorproducts/vendorproducts.service';
import {VendorGroupsPlansService} from '../../../services/vendor-groups-plans/vendor-groups-plans.service';
import {UtilsService} from '../../../services/utils/utils.service';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-vendor-groups-plans',
  templateUrl: './vendor-groups-plans.component.html',
  styleUrls: ['./vendor-groups-plans.component.scss']
})
export class VendorGroupsPlansComponent implements OnInit {

  @ViewChild(MatSort) sort!: MatSort;

  protected allColumnNames = ['vendorGroupProductId', 'metadata', 'actionmenu'];
  protected items = new MatTableDataSource<any>();
  protected groupProducts: any;

  private addEditFields = [
    {name: 'vendorGroupProductId', label: 'Group Product', fieldType: FieldType.F_DROPDOWN, values: [], required: true},
    {name: 'metadata', label: 'Configuration JSON', fieldType: FieldType.F_JSON, required: true},
  ];

  constructor(
      private vendorGroupsProductsService: VendorGroupsProductsService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private uiAlertService: UiAlertService,
      private vendorGroupsPlansService: VendorGroupsPlansService,
      private utilsService: UtilsService
  ) {

  }

  ngOnInit(): void {
    this.reloadItems();
  }

  private reloadItems(): void {
    this.vendorGroupsPlansService.getAllVendorGroupPlans().then(plans => {
      this.items.data = plans;
      this.items.sort = this.sort;
    });
    this.vendorGroupsProductsService.getAllVendorGroupProducts().then(products => {
      this.groupProducts = products;
      this.addEditFields[0].values = products.map((vendor: any) => vendor.vendorProductName);
    });
  }

  getGroupProduct(item: any): string {
    const groupProduct = this.groupProducts.find((product: { id: any; }) => product.id === item.vendorGroupProductId);
    return groupProduct ? groupProduct.vendorProductName : '';
  }

  addItem(): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(null, this.addEditFields, 'Group Plan')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsPlansService.createVendorGroupPlan(dialogResult.vendorGroupProductId, dialogResult.metadata).then(() => {
          this.reloadItems();
          this.snackBar.open('New group plan created');
        });
      }
    });
  }

  editItem(item: any): void {
    const dialogRef = this.dialog.open(MemberAddeditComponent, {
      data: new MemberAddeditDialogModel(item, this.addEditFields, 'Group Plan')
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.vendorGroupsPlansService.modifyVendorGroupPlan(item.id, dialogResult).then(() => {
          this.reloadItems();
          this.snackBar.open('Group plan updated');
        });
      }
    });
  }

  duplicateItem(item: any): void {
    this.vendorGroupsPlansService.createVendorGroupPlan(item.vendorGroupProductId, item.metadata).then(() => {
      this.reloadItems();
      this.snackBar.open('Duplicate group plan created');
    });
  }

  removeItem(item: any): void {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this group plan?').then(ok => {
      this.vendorGroupsPlansService.deleteVendorGroupPlan(item.id).then(() => {
        this.reloadItems();
        this.snackBar.open('Group plan removed');
      });
    });
  }

  truncateJSONString(str: string, maxLength: number): string {
    str = JSON.stringify(str);
    return str.length <= maxLength ? str : str.slice(0, maxLength - 3) + '...';
  }

}
